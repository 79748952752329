<template>
  <div class="fullScreen" :class="{ show: showEditDialog }">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ options.title }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading" id="Scrollbar">
      <div class="fullScreenMainCon">
        <el-form style="margin: 20px 0" ref="form" :rules="rulesForm" :model="form">
          <el-descriptions style="width: 100%" direction="vertical" :column="8" border>
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
              label="单据编号"
              v-if="options.id"
              prop="expenseNumber"
              ref="expenseNumber"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="申请人"
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <el-form-item prop="createName" label-width="0px">
                {{ form.createName }}
              </el-form-item></el-descriptions-item
            >
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                费用所属单位
              </template>
              <el-form-item prop="companyType" label-width="0px">
                <span v-if="options.disabled"> {{ form.companyType | dict(companyTypeList) }}</span>
                <Dictionary
                  v-else
                  v-model="form.companyType"
                  code="COMPANY_TYPE"
                  :clearable="false"
                  placeholder="请选择费用所属单位"
                /> </el-form-item
            ></el-descriptions-item>
            <el-descriptions-item
              label="部门"
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <el-form-item prop="deptId" label-width="0px">
                {{ form.deptName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="申请日期"
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <el-form-item prop="taskDate" label-width="0px">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="归还日期"
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                归还日期
              </template>
              <el-form-item prop="counteractDate" label-width="0px">
                <span v-if="options.disabled"> {{ form.counteractDate | dateFormat }}</span>
                <el-date-picker
                  v-else
                  style="width: 100%"
                  v-model="form.counteractDate"
                  type="date"
                  placeholder="选择日期"
                  :clearable="false"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
            >
              <template slot="label"> 归还总金额（元） </template>
              <el-form-item prop="cost">
                <span v-show="form.cost <= 0"></span>
                <span v-show="form.cost > 0"> {{ form.cost | applyAmount }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
              label="金额大写"
              :span="options.id ? 1 : 2"
              style="width: 100%"
            >
              <span v-show="form.cost <= 0"></span>
              <span v-show="form.cost > 0"> {{ form.cost | numberParseChina }}</span>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
              label="归还说明"
              :span="6"
            >
              <template slot="label">
                <span style="color: red">*</span>
                归还说明
              </template>
              <el-form-item prop="remark" label-width="0px">
                <span v-if="options.disabled"> {{ form.remark }}</span>
                <el-input
                  v-else
                  type="textarea"
                  style="width: 100%"
                  v-model="form.remark"
                  placeholder="请输入费用归还说明"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="labelClassName"
              contentClassName="contentClassName"
              label="凭证附件"
              :span="2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                <span style="vertical-align: middle"> 凭证附件 </span>
                <Upload
                  v-show="!options.disabled"
                  class="upload"
                  :type="type"
                  :fields="fields"
                  @upload="upload"
                >
                </Upload>
              </template>
              <div v-for="(n, index) in form.expenseFileList" :key="index" style="margin-top: 5px">
                <a
                  v-if="isShowType(n)"
                  style="text-decoration: revert; color: #409eff; padding-right: 8px"
                  target="_blank"
                  :href="`${filepath}${n.filePath}`"
                >
                  {{ n.fileName }}
                </a>
                <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>

                <i
                  @click="del(index, n)"
                  v-show="!options.disabled"
                  style="margin-right: 12px"
                  class="iconfont iconerror iconCost color_r"
                ></i>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>

        <el-button
          style="margin-top: 10px"
          v-if="!options.disabled"
          type="primary"
          @click="isFunds = true"
        >
          添加经费预支单
        </el-button>
        <el-table
          :data="form.counteractExpenseList"
          border
          id="table"
          style="margin-top: 10px"
          :row-style="{ height: '80px' }"
        >
          <!-- 删除 -->
          <el-table-column label="删除" v-if="!options.disabled" width="70">
            <template slot-scope="scope">
              <i
                class="el-icon-remove-outline iconCost"
                @click="expenditureRemove(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

          <el-table-column
            prop="expenseNumber"
            align="center"
            label="单据编号"
            min-width="160"
            :show-overflow-tooltip="false"
          >
          </el-table-column>
          <el-table-column
            prop="taskDate"
            align="center"
            label="申请日期"
            min-width="110"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="cost"
            align="center"
            label="预支总金额"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">{{ scope.row.cost | applyAmount }} </template>
          </el-table-column>
          <el-table-column
            prop="sumNotCounteractCost"
            align="center"
            label="未冲销/归还总金额"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope"
              >{{ scope.row.sumNotCounteractCost | applyAmount }}
            </template>
          </el-table-column>
          <el-table-column
            prop="counteractCost"
            align="center"
            label="归还金额"
            :show-overflow-tooltip="false"
          >
            <template slot="header" slot-scope="scope">
              <div><span style="color: red">* </span> 归还金额</div>
            </template>
            <template slot-scope="scope">
              <template v-if="!options.disabled">
                <el-input
                  @blur="expenditureBlur(scope.row)"
                  type="number"
                  class="numrule"
                  placeholder="请输入"
                  v-model="scope.row.counteractCost"
                >
                </el-input>
                <div v-show="scope.row.isWarning" style="color: red">
                  归还金额不能大于未冲销/归还总金额
                </div>
                <div v-show="!Number(scope.row.counteractCost)" style="color: red">金额不能为0</div>
              </template>
              <span v-else>{{ scope.row.counteractCost | applyAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-descriptions title="" :column="2" border>
          <el-descriptions-item label="归还金额" labelStyle="width:120px">
            <template v-if="form.cost">
              {{ form.cost | applyAmount }}
            </template>
            <template v-else> 0 </template>
          </el-descriptions-item>
          <el-descriptions-item label="归还总金额" labelStyle="width:120px">
            {{ thisTimeMoney | applyAmount }}
          </el-descriptions-item>
        </el-descriptions> -->

        <OpinionArea
          ref="opinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'经费预支归还进度'"
        ></OpinionArea>
      </div>

      <div class="fullScreenOperation shadow">
        <template v-if="options.isApply && form.status != 10">
          <el-button class="teal_bg" type="success" @click="handleSubmit">提交</el-button>
          <el-button type="success" @click="handleSave">{{
            options.id ? '保存' : '暂存'
          }}</el-button>
        </template>
        <template v-if="options.isExamine">
          <el-button type="success" @click="handleAdopt">通过</el-button>
          <SelectDialog
            v-if="form.status == 10 && permission(['FLOW_HAND_OVER'])"
            @selectDataChange="selectAuditor"
            style="display: inline-block"
          >
            <el-button slot="button" type="primary" :loading="loading"> 转审 </el-button>
          </SelectDialog>
          <el-button type="danger" @click="handleFail">不通过</el-button>
        </template>
        <el-button v-if="options.isReject && form.status == 10" type="success" @click="rejectFn"
          >撤回</el-button
        >
        <SelectDialog
          v-if="options.isAddAuditor && form.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
          @selectDataChange="selectAddAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary" :loading="loading"> 增加待办人 </el-button>
        </SelectDialog>
        <el-button type="warning" v-if="options.isPrint || form.status > 5" @click="printFn"
          >预览打印</el-button
        >
        <el-button v-if="options.isExport || form.status > 5" type="success" @click="exportFn"
          >导出</el-button
        >
        <el-button type="info" plain @click="returnFn">返回</el-button>
      </div>
    </div>

    <EditDialog :isShow.sync="isAdopt" :isReturn="true" @closeFn="cancel">
      <template v-slot:title>
        <span style="color: #409eff">经费预支归还通过审批</span>
      </template>
      <template v-slot:content>
        <el-form class="column3" label-width="6em" ref="ruleForm" :model="examineForm">
          <el-form-item label="审核意见" style="width: 100%">
            <el-input v-model="examineForm.opinion" type="textarea" placeholder="通过"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="success" @click="submit">审批通过</el-button>
        <el-button type="warning" plain @click="cancel">取消</el-button>
      </template>
    </EditDialog>
    <EditDialog :isShow.sync="isFail" :isReturn="true" @closeFn="cancel">
      <template v-slot:title>
        <span style="color: #409eff">经费预支归还不通过审批</span>
      </template>
      <template v-slot:content>
        <el-form
          class="column3"
          label-width="6em"
          ref="ruleForm"
          :model="examineForm"
          :rules="rules"
        >
          <el-form-item label="审核意见" prop="opinion" style="width: 100%">
            <el-input
              v-model="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button type="danger" @click="submit">审批不通过 </el-button>
        <el-button type="warning" plain @click="cancel">取消</el-button>
      </template>
    </EditDialog>

    <!-- 报销详情 -->
    <CostDetails
      @CostDetailsFn="CostDetailsFn"
      :costDetailDialog_show.sync="costDetailDialog"
      :options="costOptions"
    ></CostDetails>

    <DialogImg
      v-if="imgDialogVisible"
      :dialogVisible.sync="imgDialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
    <!-- 经费预支弹窗 -->
    <ExpenditureAdvance
      v-if="isFunds"
      :pitchOnList="pitchOnList"
      @saveFn="expenditureFn"
      :isShow.sync="isFunds"
    ></ExpenditureAdvance>
    <!-- 经费预支详情 -->
    <ExpenditureAdvanceDetails
      :isShow.sync="expenditureIsShow"
      :options="expenditureOptions"
      @closeExpenditure="expenditureIsShow = false"
    ></ExpenditureAdvanceDetails>
    <!-- 提交意见 -->
    <SubmitOpinionDialog
      v-if="submitDialog"
      :isShow.sync="submitDialog"
      @offsetCancel="submitDialog = false"
      @submitOpinionFn="submitOpinionFn"
    />

    <!-- 打印 -->
    <PrintDialog
      v-if="printDiaLog"
      :printDiaLog.sync="printDiaLog"
      :innerArr="printArr"
      :form="form"
      :companyType="companyTypeList"
    />
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    CostDetails: () => import('@/components/funds/cost-details.vue'),
    ExpenditureAdvance: () => import('@/components/funds/expenditure-advance.vue'),
    ExpenditureAdvanceDetails: () => import('@/components/funds/expenditure-advance-details.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    SubmitOpinionDialog: () => import('@/components/submit-opinion-dialog.vue'),
    PrintDialog: () => import('@/components/funds/printDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {
          title: '', //面包屑
          isExamine: false, //审核
          disabled: false, //编辑
          isApply: false, //新增
          isReject: false, //撤回
          isExport: false, //导出
        }
      },
    },
  },
  data() {
    return {
      showEditDialog: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      form: {
        // 申请 表单数据
        deptId: JSON.parse(localStorage.getItem('userInfo')).deptIdList[0],
        deptName: JSON.parse(localStorage.getItem('userInfo')).deptNames.split(',')[0],
        createName: JSON.parse(localStorage.getItem('userInfo')).userName,
        createBy: JSON.parse(localStorage.getItem('userInfo')).staffId,
        taskDate: new Date().getTime(),
        counteractDate: null,
        companyType: null,
        remark: null,
        cost: null,
        counteractExpenseList: [],
        counteractDeleteList: [],
        expenseFileList: [],
        fileDeleteList: [],
      },
      // pickerOptions: {
      //   //预支冲销/归还日期范围限制 当天到45天（自然日）
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() || time.getTime() > Date.now() + 45 * 8.64e7
      //   },
      // },
      examineForm: {
        // 审批 表单数据
        opinion: '',
        status: 0,
      },
      rules: {
        // 审核表单 验证
        opinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
      rulesForm: {
        // 单据表单 验证
        remark: [
          {
            required: true,
            message: '请输入费用归还说明',
            trigger: 'blur',
          },
        ],
        companyType: [
          {
            required: true,
            message: '请选择费用所属单位',
            trigger: ['blur', 'change'],
          },
        ],
        counteractDate: [
          {
            required: true,
            message: '请选择归还日期',
            trigger: ['blur', 'change'],
          },
        ],
      },
      filepath: process.env.VUE_APP_FILEPATH, //文件上传
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'JFYZGHtype',
      },
      type: 'JFYZGH',
      isAdopt: false, //审核通过
      isFail: false, //审核不通过
      instInvolved: {}, //当前进度处理人数组
      costOptions: {}, //预支经费中报销
      costDetailDialog: false, //预支经费中报销
      imgDialogVisible: false, //图片显示
      imgList: [], //图片预览数组
      temporaryList: [], //图片临时数组
      img_index: 0, //当前点击图片下标
      isFunds: false, //预支弹窗
      pitchOnList: [], //预支选中数组
      expenditureOptions: {}, //预支详情
      expenditureIsShow: false, //预支详情弹窗
      isAddAuditor: false, //新增待办人
      submitDialog: false, //提交意见弹窗
      printDiaLog: false, //打印
      companyTypeList: [], //费用所属单位
      printArr: [],
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        if (newVal.id) {
          this.getDetails()
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          newVal.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        }
      },
    },
    thisTimeMoney: {
      immediate: true,
      handler: function (val) {
        this.form.cost = val
      },
    },
  },
  created() {
    // 费用所属单位
    this.$api.dict
      .listSysDictData('COMPANY_TYPE')
      .then(res => {
        this.companyTypeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  computed: {
    // 本次归还总金额
    thisTimeMoney() {
      let num = 0
      if (this.form.counteractExpenseList) {
        this.form.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      num = num.toFixed(2)
      return num
    },
  },
  methods: {
    // 打印
    printFn() {
      this.$nextTick(() => {
        let arr = []
        const list = this.$refs.opinionArea.opinionRecordList
        list.forEach(v => {
          if (v.isCheckPass == 1) {
            arr.push(v)
          }
        })

        let newArr = arr.reduce((filteredArr, item) => {
          const index = filteredArr.findIndex(el => el.step === item.step)
          if (index !== -1) {
            const existingItem = filteredArr[index]
            if (item.auditDate > existingItem.auditDate) {
              filteredArr[index] = item
            }
          } else {
            filteredArr.push(item)
          }
          return filteredArr
        }, [])

        const counteractExpenseList = [...this.form.counteractExpenseList]
        let num = 0
        const printData = []
        if (counteractExpenseList.length === 0) {
          num = (counteractExpenseList.length % 5) + 1
        } else {
          num = counteractExpenseList.length % 5
        }
        while (num < 5 && num !== 0) {
          counteractExpenseList.push({
            counteractCost: '',
            sumNotCounteractCost: '',
            cost: '',
            taskDate: '',
            expenseNumber: '',
          })
          num = counteractExpenseList.length % 5
        }
        const batchSize = 5 // 每个子数组的大小
        for (let i = 0; i < counteractExpenseList.length; i += batchSize) {
          printData.push(counteractExpenseList.slice(i, i + batchSize))
        }
        this.printArr = []
        printData.forEach((v, i) => {
          this.printArr.push({
            counteractExpenseList: v,
            auditUserName: newArr[0] ? newArr[0] : '',
            createName: this.form.createName,
          })
        })
        this.printDiaLog = true
      })
    },
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.form.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 经费预支-详情
    expenditureDetailFn(row) {
      this.expenditureOptions = { ...row, id: row.advanceId }
      this.expenditureIsShow = true
    },
    //  经费预支-金额失焦
    expenditureBlur(row) {
      this.form.counteractExpenseList.forEach(v => {
        if (v.advanceId === row.advanceId) {
          if (Number(v.counteractCost) < 0) {
            v.counteractCost = 0
          }
          v.counteractCost = Number(v.counteractCost).toFixed(2)
          if (v.counteractCost > row.sumNotCounteractCost) {
            v.isWarning = true
          } else {
            v.isWarning = false
          }
        }
      })
    },
    // 经费预支-删除行
    expenditureRemove(index, row) {
      this.form.counteractExpenseList.splice(index, 1)
      this.pitchOnList.splice(index, 1)
      if (row.id) {
        this.form.counteractDeleteList.push(row.id)
      }
    },
    // 选中值
    expenditureFn(arr) {
      arr.forEach(v => {
        if (!this.pitchOnList.find(item => item.id === v.id)) {
          this.form.counteractExpenseList.push({
            advanceId: v.id,
            expenseNumber: v.expenseNumber,
            taskDate: v.taskDate,
            cost: v.cost,
            sumNotCounteractCost: v.sumNotCounteractCost,
            counteractCost: null,
            isWarning: false,
          })
          this.pitchOnList.push(v)
        }
      })
    },
    upload(r) {
      this.temporaryList = []
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.temporaryId = Date.now()
        }
      })
      this.form.expenseFileList.push(...r)
      this.temporaryList.push(...r)
    },
    del(index, n) {
      //index表示当前行的下标
      if (n.id) {
        this.form.fileDeleteList.push(n.id)
      }
      this.form.expenseFileList.splice(index, 1)
      this.imgList.forEach((item, i) => {
        if (item.temporaryId && n.temporaryId && item.temporaryId == n.temporaryId) {
          this.imgList.splice(i, 1)
        } else if (item.id == n.id) {
          this.imgList.splice(i, 1)
        }
      })
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
        ) {
          this.img_index = i
        }
      })
      this.imgDialogVisible = true
    },
    // 预支经费中报销
    CostDetailsFn() {
      this.costOptions = {}
      this.costDetailDialog = false
    },
    // 撤回
    rejectFn() {
      this.loading = true
      this.$api.fundsGiveBack
        .reject({
          id: this.form.id,
        })
        .then(res => {
          this.$message({
            message: '撤回成功!',
            type: 'success',
          })
          this.loading = false
          this.returnFn()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 导出
    exportFn() {
      let time = new Date(this.$refs.opinionArea?.opinionRecordList[0].auditDate).format(
        'yyyy-MM-dd'
      )
      let type = this.$refs.opinionArea?.opinionRecordList[0].type
      let str = `${type}&${time}&${this.form.createName}&${this.form.cost}元`
      this.$api.fundsGiveBack
        .exporting(this.form.id)
        .then(res => {
          const fileName = `${str}.xlsx`
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
            this.$message({
              message: '导出成功!',
              type: 'success',
            })
            document.body.removeChild(link) //下载完成移除元素
          }
        })
        .catch(e => {})
    },
    // 返回
    returnFn() {
      this.re()
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.form = {}
    },

    /** 加载 "经费申请" 详情数据 **/
    async getDetails() {
      this.loading = true
      this.$api.fundsGiveBack
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
            this.form.counteractDeleteList = []
            this.form.fileDeleteList = []
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }

            this.pitchOnList = []
            this.form.counteractExpenseList.forEach(v => {
              v.isWarning = false
              this.pitchOnList.push({
                id: v.advanceId,
                expenseNumber: v.expenseNumber,
                taskDate: v.taskDate,
                cost: v.cost,
                counteractCost: v.counteractCost,
                sumNotCounteractCost: v.sumNotCounteractCost,
              })
            })
            if (this.$refs.form) {
              this.$refs.form.clearValidate()
            }
            if (res.data.expenseFileList) {
              this.imgList = []
              res.data.expenseFileList.forEach(v => {
                if (!this.isShowType(v)) {
                  this.imgList.push(v)
                }
              })
            }
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 点击 "提交" 按钮 **/
    handleSubmit() {
      if (this.getVerify('submit')) return
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.submitDialog = true
          this.loading = false
        }
      })
    },
    // 提交意见
    submitOpinionFn(opinion) {
      this.loading = true
      // 状态为 5并且有流程ID 是驳回，有流程ID并且状态为0 是撤回
      if (
        (this.form.processInstId && this.form.status == '5') ||
        (this.form.processInstId && this.form.status == 0)
      ) {
        this.$api.fundsGiveBack
          .resubmitByReject({ ...this.form, opinion })
          .then(res => {
            this.loading = false
            this.$message.success('操作成功！')
            this.returnFn()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      } else {
        this.$api.fundsGiveBack
          .startProcess({ ...this.form, opinion })
          .then(res => {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
      this.submitDialog = false
    },

    /** 点击 "暂存" 按钮 **/
    handleSave() {
      if (this.getVerify()) return
      this.loading = true
      if (this.options.id) {
        this.$api.fundsGiveBack
          .undeterminedEdit(this.form)
          .then(res => {
            this.$message({
              message: '编辑成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      } else {
        this.$api.fundsGiveBack
          .tempAdd(this.form)
          .then(res => {
            this.$message({
              message: '暂存成功',
              type: 'success',
            })
            this.loading = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
    },

    /** 点击 "通过" 按钮 **/
    handleAdopt() {
      this.examineForm.status = 1
      this.isAdopt = true
    },

    /** 点击 "不通过" 按钮 **/
    handleFail() {
      this.examineForm.status = 2
      this.isFail = true
    },
    /** 审核按钮 **/
    submit() {
      const obj = {
        id: this.form.id,
        opinion: this.examineForm.opinion,
      }
      if (this.examineForm.status === 1) {
        obj.opinion = obj.opinion ? obj.opinion : '通过'
        obj.processInstId = this.form.processInstId
        this.loading = true
        this.$api.addAuditor
          .disposeTask(obj)
          .then(res => {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
            this.loading = false
            this.isAdopt = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.isAdopt = false
          })
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.loading = true
            this.$api.fundsGiveBack
              .approvalReject(obj)
              .then(res => {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                })

                this.loading = false
                this.isFail = false
                this.returnFn()
              })
              .catch(err => {
                console.log(err)
                this.loading = false
                this.isFail = false
              })
          }
        })
      }
    },
    // 关闭弹窗
    cancel() {
      this.isAdopt = false
      this.isFail = false
      this.examineForm = {
        opinion: '',
        status: 0,
        id: null,
      }
    },
    // 校验
    getVerify(type = '') {
      let pass = false
      if (this.form.counteractExpenseList.find(v => v.isWarning)) {
        pass = true
      }
      if (this.form.counteractExpenseList.find(v => !Number(v.counteractCost))) {
        pass = true
      }
      if (this.form.counteractExpenseList && this.form.counteractExpenseList.length <= 0) {
        this.$message.warning('请选择预支经费单据')
        pass = true
      }
      if (type == 'submit') {
        if (this.form.expenseFileList && this.form.expenseFileList.length <= 0) {
          this.$message.warning('请上传凭证附件')
          pass = true
        }
      }
      return pass
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.color_r {
  color: #f00;
}

.el-form-item {
  margin-bottom: 0px !important;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.labelClassName {
  width: 12.5%;
}
/deep/.contentClassName {
  height: 75px !important;
}

.upload {
  color: #56a9ff;
  font-size: 30px;
  margin-left: 2%;
  vertical-align: middle;
  padding-top: 5px;
}
</style>
